import { Link } from "react-router-dom";

const Coursebox = (props) => <>
<div className="col-lg-4 col-md-6 col-12 aos-init" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
    <div className="course-box">
        <Link to={"/course/"+props.url} className="img-wrapper stretched-link" title={props.title}>
            <img src={`https://assets.thevoicebusiness.com.au/${props.dp}`} alt={props.title} />
        </Link>
        <div className="overlay-box">
            <Link to={"/course/"+props.url} className="arrow" title={props.title}>
                <img src="/images/icons/arrow-right.svg" alt="arrow" />
            </Link>
            <h3 className="title">
                {props.title}
            </h3>
            <p>{props.description}</p>
        </div>
    </div>
</div>
</>

export default Coursebox;