import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const H = () => {
const { hash } = useLocation();
useEffect(() => {
    if (hash.length > 0) {
        window.scroll(0,document.querySelector(hash).offsetTop);
    }
}, []);
return <header>
<div className="container">
    <nav className="navbar navbar-expand-xl py-0">
        <a className="navbar-brand d-xl-none d-block mx-0" href="/" title="The Voice Business">
            <img src="/images/logo.svg" alt="logo" className="w-100"
            width={(window.width < 500) ? "369" : "395"}
            height={(window.width < 500) ? "80" : "100"}
            />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
        <Link className="navbar-brand d-xl-block d-none mx-0" to="/">
                <img src="/images/logo.svg" alt="The Voice Business logo" />
            </Link>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/about">About Us</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/national-program">Courses</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/contact">Contact</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/booking">Bookings</Link>
                </li>
                <li className="nav-item">
                    <div className="icon-box">
                        <div className="icon">
                            <img src="/images/icons/call-icon.svg" alt="call icon" />
                        </div>
                        <div>
                            <p className="mb-0">Call us</p>
                            <a href="tel:1300922122">1300 922 122</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>
</header>}


export default H;