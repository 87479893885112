import { useParams } from "react-router-dom"
import Contactform from "../components/contactform"
import F from "../components/footer"
import H from "../components/header"

const City = () => {
    window.scroll(0,0);
    const {city} = useParams();
    const cityName = city[0].toUpperCase() + city.substring(1);
    window.document.title = `Voice coaching and training in ${cityName}`;
window.document.querySelector('meta[name="description"]').setAttribute("content", `Contact form for voice coaching and training in ${cityName}`);
window.document.querySelector('meta[name="keywords"]').setAttribute("content", `contact form, voice coaching in ${cityName}, voice training in ${cityName}`);
    return <>
    <H />
<section id="citypage" style={{backgroundImage: `url(/images/city/${city}.jpeg)`}}>
    <div className="container">
        <div className="row">
            <div className="mx-auto col-lg-8 col-md-11 col-12">
        <h1>{`Voice coaching and training in ${cityName}`}</h1>
            </div>
        </div>
    </div>
</section>
<Contactform city={cityName} />
    <F />
    </>
}

export default City;
