import F from "../components/footer";
import H from "../components/header";
import Bluesection from "../components/bluesection";
import T from "../components/testimonials";
import Courses from "../components/courses";
import { useEffect, useState } from "react";

const Home = () => {
    const [data, setData] = useState(false);
    useEffect(() => {
        fetch('https://api.thevoicebusiness.com.au/page/home')
        .then(res => res.json()).then(jr => { let c = JSON.parse(jr['content']); setData(c);
            window.document.title = jr.title;
            window.document.querySelector('meta[name="description"]').setAttribute("content", jr.description);
            window.document.querySelector('meta[name="keywords"]').setAttribute("content", jr.keywords);
         });
    }, []);
    return <><H />
<section className="d-flex flex-column position-relative">
<video autoPlay muted playsInline loop id="brandvideo" poster="/images/43335061605.jpg">
        <source src="/video/voice-business-bg-video.mp4" type="video/mp4" />
</video>
    <div className="container">
        <div className="row">
            <div className="col-md-9 mx-auto position-relative">
            <div className="position-absolute bottom-0 mb-3">
                <h1 className="main-title">Get expert training in <span className="weight-font">Public speaking,</span> <span className="blue-text">Presentation skills</span> & <span className="red-text weight-font">Voice coaching</span>
                </h1>
            </div>
        </div>
        </div>
    </div>
</section>
    <Courses data={data} />
        <T />
        <Bluesection data={data} />
    <F />
</>
}

export default Home;