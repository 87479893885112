import { Link } from "react-router-dom";

const B = ({data}) => {

return (!data) ? <div className="loadcourse"></div> :
<section className="ab-wrapper">
<div className="container">
    <div className="row">
        <div className="col-md-11 mx-auto">
            <p className="mb-0 sub-title text-center">The Voice Business</p>
            <h2 className="second-title text-center">{data['Blue Heading'].replace(/<\/?[^>]+(>|$)/g, "")}</h2>
            <div className="col-md-12">
<div className="para text-center mt-md-5 mt-2" dangerouslySetInnerHTML={{__html: data['Blue Mid section']}}></div>
            </div>
        </div>
    </div>

    <div className="row ab-inner-wrapper top">
        <div className="col-lg-6">
            <div className="ab-text-wrapper">
<div className="para" dangerouslySetInnerHTML={{__html: data['Blue upper section']}}></div>
            </div>
        </div>
        <div className="col-lg-6 aos-init" data-aos="zoom-in" data-aos-duration="2000">
            <div className="ab-img-wrapper">
                <img src="/images/woman-counselling-man.jpeg" alt="About image" />
            </div>
        </div>
    </div>

    <div className="row ab-inner-wrapper bottom">
        <div className="col-lg-6">
            <div className="ab-img-wrapper aos-init" data-aos="zoom-in-up" data-aos-duration="2000">
                <img src="/images/woman-talking-and-meeting.jpeg" alt="About image" />
            </div>
        </div>
        <div className="col-lg-6">
            <div className="ab-text-wrapper">
<div className="para text-white" dangerouslySetInnerHTML={{__html: data['Blue lower section']}}></div>
            </div>
        </div>
    </div>

    <div className="row">
        <div className="col-12 mt-lg-5 mt-md-3 mt-2">
            <Link to="/booking" className="theme-btn white-btn mx-auto mt-lg-4 mt-md-2 mt-2">Just do a Course
                <i>
                    <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 5.2825C0.603738 5.2825 0.282504 5.60374 0.282504 6C0.282504 6.39626 0.603738 6.7175 1 6.7175V5.2825ZM21.8364 6.50735C22.1166 6.22715 22.1166 5.77285 21.8364 5.49265L17.2703 0.92654C16.9901 0.646341 16.5358 0.646341 16.2556 0.92654C15.9754 1.20674 15.9754 1.66103 16.2556 1.94123L20.3143 6L16.2556 10.0588C15.9754 10.339 15.9754 10.7933 16.2556 11.0735C16.5358 11.3537 16.9901 11.3537 17.2703 11.0735L21.8364 6.50735ZM1 6.7175H21.329V5.2825H1V6.7175Z" fill="#0F1E58"></path>
                    </svg>
                </i>
            </Link>
        </div>
    </div>
</div>
</section>}

export default B;