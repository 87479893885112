const Testimonialblock = (props) => <article className="testimonial col-md-4 coursedetails">
<div itemScope itemType="http://schema.org/Product" className="unsetdiv">
<div itemProp="review" itemScope itemType="http://schema.org/Review">
<figure itemProp="reviewRating" itemScope itemType="http://schema.org/Rating">
<meta itemProp="worstRating" content="1" />
<meta itemProp="bestRating" content="5" />
<meta itemProp="ratingValue" content="5" />
<figcaption>⭐⭐⭐⭐⭐</figcaption>
</figure>
<p itemProp="author" className="fw-bold mt-2">{props.name}</p>
<p itemProp="description">{props.testimonial}</p>
</div>
</div>
</article>

export default Testimonialblock;