import { useEffect, useState } from "react"
import F from "../components/footer"
import H from "../components/header"
import { useParams } from "react-router-dom";

const Some = () => {
    const {country} = useParams();
    const contentClass = (country === 'emirates') ? "col-md-10 col-xs-9 col-11 mx-auto text-end" : "col-md-10 col-xs-9 col-11 mx-auto";
    const [data, setData] = useState({ name:'', details:'', picture:'/images/bg-lnes.png' });
    useEffect(() => {
        fetch(`https://api.thevoicebusiness.com.au/internationals/${country}`)
        .then(r => r.json()).then(j => {
            window.scroll(0,0);
            j.picture = `https://assets.thevoicebusiness.com.au/${j.picture}`;
            window.document.title = j.name + " | " + window.document.title;
            window.document.querySelector('meta[name="description"]').setAttribute("content", "The Voice Business welcomes international enquiries from "+country);
            window.document.querySelector('meta[name="keywords"]').setAttribute("content",`${country}, the voice business, tvb international, international voice coaching`);
            setData(j);
        });
}, [country]);
    return <>
<H />
<div className="headerimage" style={{backgroundImage: `url(${data.picture})`}}>
    <h1 className="rounded rounded-3">The Voice Business - {data.name}</h1>
</div>
<div className="container mb-5">
    <div className="row my-5">
        <div className={contentClass} dangerouslySetInnerHTML={{__html: data.details}}></div>
    </div>
    <div className="d-md-flex justify-content-center">
        <a href="tel:+61299574208" className="theme-btn mx-2 mt-3 mb-md-0 mb-3">Call +61 2 9957 4208</a>
        <a href="/contact" className="theme-btn mt-3 mx-2 mb-md-0 mb-3">Contact us</a>
    </div>
</div>
<F />
</>
}


export default Some;