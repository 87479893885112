import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import H from '../components/header';
import F from '../components/footer';
const Page = () => {
    const {url} = useParams();
    const [pagecontent, setPagecontent] = useState({heading:'', terms:''});
    useEffect(() => {
fetch('https://api.thevoicebusiness.com.au/page/'+url, {headers: {'content-type':'application/json'}})
    .then(r => r.json()).then(jr => {
        window.document.title = jr.title;
        setPagecontent(JSON.parse(jr.content));
window.document.querySelector('meta[name="description"]').setAttribute(jr.title + ' - The Voice Business');
window.document.querySelector('meta[name="keywords"]').setAttribute(`${jr.title}, the voice business`);
    });
    }, [url]);
    return <>
    <H />
    <div className='my-5 container white-box'>
    <h1>{pagecontent['heading'].replace(/<\/?[^>]+(>|$)/g, "")}</h1>
    <br />
    <hr />
    <br />
    <div dangerouslySetInnerHTML={{__html: pagecontent['details']}}></div>
    </div>
    <F />
    </>
}

export default Page;