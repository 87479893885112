import H from "../components/header";
import F from "../components/footer";
import Contactform from "../components/contactform";

const Contact = () => {
window.document.title = "Contact form and Contact information of The Voice Business";
window.document.querySelector('meta[name="description"]').setAttribute("content", "Contact information of The Voice Business Sydney. Additionally, conducts international classes through online channels.");
window.document.querySelector('meta[name="keywords"]').setAttribute("content", "Contact information of The Voice Business, contact us, connect with The Voice Business, Voice Business");
return <><H />
<main className="contact-page pages">
<section className="d-flex flex-column position-relative">
<video autoPlay muted playsInline loop id="brandvideo" className="mt-0">
        <source src="/video/contact-us.mp4" type="video/mp4" />
</video>
    <div className="container">
        <div className="row">
            <div className="col-md-9 mx-auto position-relative">
            <div className="position-absolute bottom-0 mb-3">
                <h1 className="main-title">Contact <span className="blue-text">Us</span>
                </h1>
                <p className="d-none d-md-block para mt-3 text-center">Ready to find out more? <strong>Call us now </strong> or <strong>Fill out</strong> the <strong>Contact form</strong> below.</p>
            </div>
        </div>
        </div>
    </div>
</section>
       <section className="contact-info bg-blue">
        <div className="container">
            <div className="contact-box">
                <div className="row">
                    <div className="d-block d-md-none">
                        <div className="col-12">
                        <p className="para text-white text-center">Ready to find out more? <strong>Call us now </strong> or <strong>Fill out</strong> the <strong>Contact form</strong> below.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 my-2" data-aos="fade-right" data-aos-duration="1000">
                        <div className="icon-box">
                            <i className="fa fa-volume-control-phone text-blue icon"></i>
                            <div className="text-wrapper">
                                <h5>Domestic</h5>
                                <a href="tel:1300922122">1300 922 122</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 my-2" data-aos="fade-left" data-aos-duration="1000">
                        <div className="icon-box">
                            <i className="fa fa-volume-control-phone text-blue icon"></i>
                            <div className="text-wrapper">
                                <h5>International</h5>
                                <a href="tel:+61299574208">+61 2 9957 4208</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Contactform />
    </main>
<F />
</>
}


export default Contact;
