import { useEffect, useRef } from "react";
import H from "../components/header";
import F from "../components/footer";

const Booking = () => {
fetch('https://api.thevoicebusiness.com.au/ping', {credentials: 'include'});

const firstname = useRef(); const lastname = useRef(); const company = useRef();
const jobtitle = useRef(); const courseId = useRef(); const startdate = useRef();
const email = useRef(); const homephone = useRef(); const workphone = useRef();
const mobile = useRef(); const billing_address = useRef(); const billing_city = useRef();
const billing_state = useRef(); const billing_postcode = useRef(); const billing_country = useRef();
const newsletter = useRef(); const payment_option = useRef(); const course_reference = useRef();
const comments = useRef(); const tos = useRef(); const sectionc = useRef();
const sentForm = () => { window.scroll(0, sectionc.current.offsetTop - 400);
    sectionc.current.innerHTML = '<div className="col-3"><svg className="w-100" version="1.1" x="0px" y="0px" viewBox="0 0 98.5 98.5" enableBackground="new 0 0 98.5 98.5"><path className="checkmark" fill="none" strokeWidth="8" strokeMiterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/></svg></div><div className="col-9"><h3>Thank you for booking with us.<br />We have received it and will respond shortly.</h3></div>';
};

function sendBooking() {
if(!tos.current.checked) { tos.current.classList.add('is-invalid'); return false; }
document.querySelectorAll('.is-invalid').forEach(element => { element.classList.remove('is-invalid'); });
const bookingData = {};
bookingData["firstname"] = firstname.current.value;
bookingData["lastname"] = lastname.current.value;
bookingData["company"] = company.current.value;
bookingData["jobtitle"] = jobtitle.current.value;
bookingData["courseId"] = courseId.current.value;
bookingData["Course"] = courseId.current.selectedOptions[0].innerHTML;
bookingData["startdate"] = startdate.current.value;
bookingData["email"] = email.current.value;
bookingData["homephone"] = homephone.current.value;
bookingData["workphone"] = workphone.current.value;
bookingData["mobile"] = mobile.current.value;
bookingData["billing_address"] = billing_address.current.value;
bookingData["billing_city"] = billing_city.current.value;
bookingData["billing_state"] = billing_state.current.value;
bookingData["billing_postcode"] = billing_postcode.current.value;
bookingData["billing_country"] = billing_country.current.value;
document.querySelectorAll('input[name=newsletter]').forEach(e =>
    (e.checked) ? bookingData["newsletter"] = e.value : false);
document.querySelectorAll('input[name=payment]').forEach(e =>
        (e.checked) ? bookingData["payment_option"] = e.value : false);
bookingData["course_reference"] = '';
document.querySelectorAll('input[name=reference]').forEach(e =>
    (e.checked) ? bookingData["course_reference"] += e.value + ',' : false);
bookingData["course_reference"] = bookingData["course_reference"].slice(0, -1);
bookingData["comments"] = comments.current.value;
fetch('https://api.thevoicebusiness.com.au/booking', { headers: {'Content-Type': 'application/json'},
credentials: 'include', method: 'POST', body: JSON.stringify(bookingData)
}).then((res) => res.status === 409 ? res.text().then((rb => {
    let errFields = rb.split(','); errFields.pop();
    errFields.forEach(element => {
        eval(element).current.classList.add('is-invalid');
    });
    window.scroll(0, sectionc.current.offsetTop);
})) : (res.status === 418) ? alert("The form could not be sent, please refresh the page and try again.") : sentForm());
}
useEffect(() => {
    let optag = '';
    fetch('https://api.thevoicebusiness.com.au/courses?list=yes').then((res) => res.json())
    .then(jr => {jr.map(el => {
        optag += `<option value="${el.id}">${el.title}</option>`;
    });
    window.scroll(0,0);
    window.document.title = "Booking Form - Book your course at The Voice Business";
    window.document.querySelector('meta[name="description"]').setAttribute("content", "Book your course at The Voice Business by The Voice Business at Sydney, Brisbane, Melbourne, Perth, Adelaide and Canberra");
    window.document.querySelector('meta[name="keywords"]').setAttribute("content", "Book public speaking course, elocution lessons training, executive speaking skills training");
    courseId.current.innerHTML = optag;
    let s = document.location.search.slice(1,).split('=');
    if(s.length > 1) {
        courseId.current.value = [s[1]];
    }
    }); }, []);
    return <>
    <H />
    <section className="d-flex flex-column position-relative">
<video autoPlay muted playsInline loop id="brandvideo">
        <source src="/video/booking.mp4" type="video/mp4" />
</video>
    <div className="container pages">
        <div className="row">
            <div className="col-md-9 mx-auto position-relative">
            <div className="position-absolute bottom-0 mb-3">
                <h1 className="main-title p-3" title="Booking form - The Voice Business">Booking</h1>
                <p className="d-none d-md-block text-white mt-3 text-center rounded rounded-4">Fill in the form and click "send". We will confirm receipt of your application by email. <br />Any information sent to us is kept strictly confidential. See <a href="/page/privacy" target="_blank" className="text-decoration-none">privacy policy</a></p>
            </div>
        </div>
        </div>
    </div>
</section>
<main className="bookings-page pages">
    <section className="bg-with-lines booking-wrapper">
        <div className="container">
            <div className="row" ref={sectionc}>
                <div className="d-block d-md-none">
                <p className="text-center rounded rounded-4">Fill in the form and click "send". We will confirm receipt of your application by email. <br />Any information sent to us is kept strictly confidential. See <a href="/page/privacy" target="_blank" className="text-decoration-none">privacy policy</a></p>
                </div>
                <div className="col-xl-9 col-lg-11 col-12 mx-auto">
                    <div className="outer-box">
                        <div className="d-flex align-items-center justify-content-center" data-aos="flip-right" data-aos-duration="1000">
                            <h3 className="d-inline-flex justify-content-center align-items-center mx-auto bg-title third-title text-white">
                                Booking Form</h3>
                        </div>
                        <div className="white-box">
                                <div className="form-wrapper row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="fName" className="form-label">First Name<span className="required">*</span>:</label>
<input type="text" className="form-control" id="fName" ref={firstname} />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="lName" className="form-label">Last Name<span className="required">*</span>:</label>
                                        <div className="input-group has-validation">
<input type="text" className="form-control" id="lName" ref={lastname} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="company" className="form-label">Company :</label>
<input type="text" className="form-control" id="company" ref={company} />

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="jTitle" className="form-label">Job Title :</label>
                                        <div className="input-group has-validation">
<input type="text" className="form-control" id="jTitle" ref={jobtitle} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        {/********** Show Courses here **********/}
                                        <label htmlFor="enroll" className="form-label">Please enroll me in<span className="required">*</span>:</label>
<select className="form-control form-select" id="enroll" ref={courseId}>
</select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="sdate" className="form-label">Preferred Start Date :</label>
                                        <div className="input-group has-validation">
<input type="date" className="form-control" id="sdate" ref={startdate} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="email" className="form-label">Email Address<span className="required">*</span>:</label>
<input type="text" className="form-control" id="email" ref={email} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="hphone" className="form-label">Home Phone :</label>
                                        <div className="input-group has-validation">
<input type="text" className="form-control" id="hphone" ref={homephone} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="wphone" className="form-label">Work Phone :</label>
                                        <div className="input-group has-validation">
<input type="text" className="form-control" id="wphone" ref={workphone} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="mphone" className="form-label">Mobile Phone<span className="required">*</span>:</label>
                                        <div className="input-group has-validation">
<input type="text" className="form-control" id="mphone" ref={mobile} />
                                        </div>
                                    </div>
                                </div>
                                <div className="border-box">
                                    <h4 className="third-title text-blue text-center">Billing Address</h4>
                                    <div className="form-wrapper row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="address"
                                                            className="form-label textarea-label">Address<span className="required">*</span>:</label>
                                                        <textarea className="form-control" id="address"
                                                            rows="5" ref={billing_address}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="col-md-12 mb-3">
<label htmlFor="stateter" className="form-label">State / Territory<span className="required">*</span>:</label>
<select className="form-select form-control" id="stateter" ref={billing_state}>
<option value="0">Select state</option> <option value="ACT">ACT</option> <option value="NSW">NSW</option>
	<option value="NT">NT</option> <option value="QLD">QLD</option> <option value="SA">SA</option>
	<option value="TAS">TAS</option> <option value="VIC">VIC</option> <option value="WA">WA</option>
	<option value="others">others...</option>
</select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="city" className="form-label">City<span className="required">*</span>:</label>
                                                    <div className="input-group has-validation">
<input type="text" className="form-control" id="city" ref={billing_city} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="pCode" className="form-label">Post Code<span className="required">*</span>:</label>
                                                    <div className="input-group has-validation">
<input type="text" className="form-control" id="pCode" ref={billing_postcode} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="country"
                                                            className="form-label textarea-label">Country<span className="required">*</span>:</label>
<select className="form-select form-control" ref={billing_country} id="country">
<option value="Australia">Australia</option>
<option value="Afghanistan">Afghanistan</option>
<option value="Albania">Albania</option>
<option value="Algeria">Algeria</option>
<option value="American Samoa">American Samoa</option>
<option value="Andorra">Andorra</option>
<option value="Angola">Angola</option>
<option value="Anguilla">Anguilla</option>
<option value="Antarctica">Antarctica</option>
<option value="Antigua and Barbuda">Antigua and Barbuda</option>
<option value="Argentina">Argentina</option>
<option value="Armenia">Armenia</option>
<option value="Aruba">Aruba</option>
<option value="Austria">Austria</option>
<option value="Azerbaijan">Azerbaijan</option>
<option value="Bahamas">Bahamas</option>
<option value="Bahrain">Bahrain</option>
<option value="Bangladesh">Bangladesh</option>
<option value="Barbados">Barbados</option>
<option value="Belarus">Belarus</option>
<option value="Belgium">Belgium</option>
<option value="Belize">Belize</option>
<option value="Benin">Benin</option>
<option value="Bermuda">Bermuda</option>
<option value="Bhutan">Bhutan</option>
<option value="Bolivia">Bolivia</option>
<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
<option value="Botswana">Botswana</option>
<option value="Bouvet Island">Bouvet Island</option>
<option value="Brazil">Brazil</option>
<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
<option value="Brunei Darussalam">Brunei Darussalam</option>
<option value="Bulgaria">Bulgaria</option>
<option value="Burkina Faso">Burkina Faso</option>
<option value="Burundi">Burundi</option>
<option value="Cambodia">Cambodia</option>
<option value="Cameroon">Cameroon</option>
<option value="Canada">Canada</option>
<option value="Cape Verde">Cape Verde</option>
<option value="Cayman Islands">Cayman Islands</option>
<option value="Central African Republic">Central African Republic</option>
<option value="Chad">Chad</option>
<option value="Chile">Chile</option>
<option value="China">China</option>
<option value="Christmas Island">Christmas Island</option>
<option value="Cocos ">Cocos (Keeling) Islands</option>
<option value="Colombia">Colombia</option>
<option value="Comoros">Comoros</option>
<option value="Congo">Congo</option>
<option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
<option value="Cook Islands">Cook Islands</option>
<option value="Costa Rica">Costa Rica</option>
<option value="Cote Divoire">Cote Divoire</option>
<option value="Croatia">Croatia</option>
<option value="Cuba">Cuba</option>
<option value="Cyprus">Cyprus</option>
<option value="Czech Republic">Czech Republic</option>
<option value="Denmark">Denmark</option>
<option value="Djibouti">Djibouti</option>
<option value="Dominica">Dominica</option>
<option value="Dominican Republic">Dominican Republic</option>
<option value="Ecuador">Ecuador</option>
<option value="Egypt">Egypt</option>
<option value="El Salvador">El Salvador</option>
<option value="Equatorial Guinea">Equatorial Guinea</option>
<option value="Eritrea">Eritrea</option>
<option value="Estonia">Estonia</option>
<option value="Ethiopia">Ethiopia</option>
<option value="Falkland Islands 'Malvinas'">Falkland Islands 'Malvinas'</option>
<option value="Faroe Islands">Faroe Islands</option>
<option value="Fiji">Fiji</option>
<option value="Finland">Finland</option>
<option value="France">France</option>
<option value="French Guiana">French Guiana</option>
<option value="French Polynesia">French Polynesia</option>
<option value="French Southern Territories">French Southern Territories</option>
<option value="Gabon">Gabon</option>
<option value="Gambia">Gambia</option>
<option value="Georgia">Georgia</option>
<option value="Germany">Germany</option>
<option value="Ghana">Ghana</option>
<option value="Gibraltar">Gibraltar</option>
<option value="Greece">Greece</option>
<option value="Greenland">Greenland</option>
<option value="Grenada">Grenada</option>
<option value="Guadeloupe">Guadeloupe</option>
<option value="Guam">Guam</option>
<option value="Guatemala">Guatemala</option>
<option value="Guinea">Guinea</option>
<option value="Guinea">Guinea-bissau</option>
<option value="Guyana">Guyana</option>
<option value="Haiti">Haiti</option>
<option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
<option value="Holy See ">Holy See (Vatican City State)</option>
<option value="Honduras">Honduras</option>
<option value="Hong Kong">Hong Kong</option>
<option value="Hungary">Hungary</option>
<option value="Iceland">Iceland</option>
<option value="India">India</option>
<option value="Indonesia">Indonesia</option>
<option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
<option value="Iraq">Iraq</option>
<option value="Ireland">Ireland</option>
<option value="Israel">Israel</option>
<option value="Italy">Italy</option>
<option value="Jamaica">Jamaica</option>
<option value="Japan">Japan</option>
<option value="Jordan">Jordan</option>
<option value="Kazakhstan">Kazakhstan</option>
<option value="Kenya">Kenya</option>
<option value="Kiribati">Kiribati</option>
<option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
<option value="Korea, Republic of">Korea, Republic of</option>
<option value="Kuwait">Kuwait</option>
<option value="Kyrgyzstan">Kyrgyzstan</option>
<option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
<option value="Latvia">Latvia</option>
<option value="Lebanon">Lebanon</option>
<option value="Lesotho">Lesotho</option>
<option value="Liberia">Liberia</option>
<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
<option value="Liechtenstein">Liechtenstein</option>
<option value="Lithuania">Lithuania</option>
<option value="Luxembourg">Luxembourg</option>
<option value="Macao">Macao</option>
<option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
<option value="Madagascar">Madagascar</option>
<option value="Malawi">Malawi</option>
<option value="Malaysia">Malaysia</option>
<option value="Maldives">Maldives</option>
<option value="Mali">Mali</option>
<option value="Malta">Malta</option>
<option value="Marshall Islands">Marshall Islands</option>
<option value="Martinique">Martinique</option>
<option value="Mauritania">Mauritania</option>
<option value="Mauritius">Mauritius</option>
<option value="Mayotte">Mayotte</option>
<option value="Mexico">Mexico</option>
<option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
<option value="Moldova, Republic of">Moldova, Republic of</option>
<option value="Monaco">Monaco</option>
<option value="Mongolia">Mongolia</option>
<option value="Montserrat">Montserrat</option>
<option value="Morocco">Morocco</option>
<option value="Mozambique">Mozambique</option>
<option value="Myanmar">Myanmar</option>
<option value="Namibia">Namibia</option>
<option value="Nauru">Nauru</option>
<option value="Nepal">Nepal</option>
<option value="Netherlands">Netherlands</option>
<option value="Netherlands Antilles">Netherlands Antilles</option>
<option value="New Caledonia">New Caledonia</option>
<option value="New Zealand">New Zealand</option>
<option value="Nicaragua">Nicaragua</option>
<option value="Niger">Niger</option>
<option value="Nigeria">Nigeria</option>
<option value="Niue">Niue</option>
<option value="Norfolk Island">Norfolk Island</option>
<option value="Northern Mariana Islands">Northern Mariana Islands</option>
<option value="Norway">Norway</option>
<option value="Oman">Oman</option>
<option value="Pakistan">Pakistan</option>
<option value="Palau">Palau</option>
<option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
<option value="Panama">Panama</option>
<option value="Papua New Guinea">Papua New Guinea</option>
<option value="Paraguay">Paraguay</option>
<option value="Peru">Peru</option>
<option value="Philippines">Philippines</option>
<option value="Pitcairn">Pitcairn</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Puerto Rico">Puerto Rico</option>
<option value="Qatar">Qatar</option>
<option value="Reunion">Reunion</option>
<option value="Romania">Romania</option>
<option value="Russian Federation">Russian Federation</option>
<option value="Rwanda">Rwanda</option>
<option value="Saint Helena">Saint Helena</option>
<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
<option value="Saint Lucia">Saint Lucia</option>
<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
<option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
<option value="Samoa">Samoa</option>
<option value="San Marino">San Marino</option>
<option value="Sao Tome and Principe">Sao Tome and Principe</option>
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Senegal">Senegal</option>
<option value="Serbia and Montenegro">Serbia and Montenegro</option>
<option value="Seychelles">Seychelles</option>
<option value="Sierra Leone">Sierra Leone</option>
<option value="Singapore">Singapore</option>
<option value="Slovakia">Slovakia</option>
<option value="Slovenia">Slovenia</option>
<option value="Solomon Islands">Solomon Islands</option>
<option value="Somalia">Somalia</option>
<option value="South Africa">South Africa</option>
<option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
<option value="Spain">Spain</option>
<option value="Sri Lanka">Sri Lanka</option>
<option value="Sudan">Sudan</option>
<option value="Suriname">Suriname</option>
<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
<option value="Swaziland">Swaziland</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="Syrian Arab Republic">Syrian Arab Republic</option>
<option value="Taiwan, Province of China">Taiwan, Province of China</option>
<option value="Tajikistan">Tajikistan</option>
<option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
<option value="Thailand">Thailand</option>
<option value="Timor">Timor-leste</option>
<option value="Togo">Togo</option>
<option value="Tokelau">Tokelau</option>
<option value="Tonga">Tonga</option>
<option value="Trinidad and Tobago">Trinidad and Tobago</option>
<option value="Tunisia">Tunisia</option>
<option value="Turkey">Turkey</option>
<option value="Turkmenistan">Turkmenistan</option>
<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
<option value="Tuvalu">Tuvalu</option>
<option value="Uganda">Uganda</option>
<option value="Ukraine">Ukraine</option>
<option value="United Arab Emirates">United Arab Emirates</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
<option value="Uruguay">Uruguay</option>
<option value="Uzbekistan">Uzbekistan</option>
<option value="Vanuatu">Vanuatu</option>
<option value="Venezuela">Venezuela</option>
<option value="Viet Nam">Viet Nam</option>
<option value="Virgin Islands, British">Virgin Islands, British</option>
<option value="Virgin Islands, U">Virgin Islands, U.S.</option>
<option value="Wallis and Futuna">Wallis and Futuna</option>
<option value="Western Sahara">Western Sahara</option>
<option value="Yemen">Yemen</option>
<option value="Zambia">Zambia</option>
<option value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrapper row">
                                    <div className="col-lg-12">
                                            <p className="form-label" ref={newsletter}>Would you like to receive our newsletters?</p>
                                            <div className="d-flex gap-4">
                                                <div className="form-check">
<input className="form-check-input" type="radio" name="newsletter" id="exampleRadios1" value="1" />
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check">
<input className="form-check-input" type="radio" name="newsletter" id="exampleRadios2" value="0" />
                                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                            <p className="form-label" ref={payment_option}>Payment Options<span className="required">*</span>:</p>
                                            <div className="form-check">
<input className="form-check-input" type="radio" name="payment" id="payment1" value="Please send an invoice" />
                                                <label className="form-check-label" htmlFor="payment1">
                                                    Please send me an invoice
                                                </label>
                                            </div>
                                            <div className="form-check">
<input className="form-check-input" type="radio" name="payment" id="payment2" value="Pay at first session" />
                                                <label className="form-check-label" htmlFor="payment2">
                                                    Pay at first session
                                                </label>
                                            </div>
                                            <div className="form-check">
<input className="form-check-input" type="radio" name="payment" id="payemnt3" value="Contact for credit card" />
                                                <label className="form-check-label" htmlFor="payemnt3">
                                                    Please contact me for credit card
                                                </label>
                                    </div>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                            <p className="form-label" ref={course_reference}>How did you hear about these courses ?</p>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Friend/Colleague" id="flexCheckfriendcolleague" />
                                                        <label className="form-check-label"
                                                            htmlFor="flexCheckfriendcolleague">
                                                            Friend/Colleague
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Google" id="flexCheckBing" />
                                                        <label className="form-check-label" htmlFor="flexCheckBing">
                                                            Google
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Website" id="flexCheckwebsite" />
                                                        <label className="form-check-label" htmlFor="flexCheckwebsite">
                                                            Website
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Newspaper" id="flexCheckNewspaper" />
                                                        <label className="form-check-label" htmlFor="flexCheckNewspaper">
                                                            Newspaper
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="TV" id="flexCheckTV" />
                                                        <label className="form-check-label" htmlFor="flexCheckTV">
                                                            TV
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Facebook" id="flexCheckYahoo" />
                                                        <label className="form-check-label" htmlFor="flexCheckYahoo">
                                                            Facebook
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Bing" id="flexCheckYellowPages" />
                                                        <label className="form-check-label" htmlFor="flexCheckYellowPages">
                                                            Bing
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Flyer / Brochure" id="flexCheckFlyerandBrochure" />
                                                        <label className="form-check-label"
                                                            htmlFor="flexCheckFlyerandBrochure">
                                                            Flyer/Brochure
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Email" id="flexCheckEmail" />
                                                        <label className="form-check-label" htmlFor="flexCheckEmail">
                                                            Email
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Radio" id="flexCheckRadio" />
                                                        <label className="form-check-label" htmlFor="flexCheckRadio">
                                                            Radio
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Instagram" id="flexCheckGoogle" />
                                                        <label className="form-check-label" htmlFor="flexCheckGoogle">
                                                            Instagram
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Other search engine" id="flexCheckSearchOther" />
                                                        <label className="form-check-label" htmlFor="flexCheckSearchOther">
                                                            Search: Other
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Direct mail" id="flexCheckDirectMail" />
                                                        <label className="form-check-label" htmlFor="flexCheckDirectMail">
                                                            Direct Mail
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Editorial article" id="flexCheckEditorialandArticle" />
                                                        <label className="form-check-label"
                                                            htmlFor="flexCheckEditorialandArticle">
                                                            Editorial / Article
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-1">
<input className="form-check-input" type="checkbox" name="reference" value="Other" id="flexCheckOtherPleasespecify" />
                                                        <label className="form-check-label"
                                                            htmlFor="flexCheckOtherPleasespecify">
                                                            Other
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                            <p className="form-label">Any comment or Request</p>
                                            <div className="col-md-12 mb-3">
                                                <div className="input-group has-validation">
                            <textarea className="form-control" id="comment" rows="4" ref={comments}></textarea>
                                                </div>
                                            </div>
                                    </div>
<div className="col-lg-12">
        <p className="form-label">Booking Conditions</p>
        <div className="condition-box">
            <ul className="number-list">
                <li>For a private booking you may pay for your course in full at
                    the time of booking or at your first session.</li>
                <li>For a corporate booking, an Invoice will be sent at time of
                    booking and payment must be received for full
                    course/workshop 7 days from receipt of invoice.</li>
                <li>If you have to cancel the booking, you will receive a full
                    refund of any monies paid if you notify us in writing 14
                    days before the start date of your course. You are entitled
                    to a 50% refund for all bookings cancelled up to the
                    commencement of your course, providing you notify us in
                    writing prior to the commencement of your course. Once a
                    course has commenced, no refunds are offered. In addition,
                    The Voice Business will be entitled to claim the cost of any
                    credit card fees and any non-refundable airfares/general
                    expenses/accommodation/venue bookings made in relation to a
                    Corporate course/ workshop booking.</li>
                <li>If you book a session and do not notify us within 24 hrs of
                    the session that you intend to miss it or do not turn up for
                    your booked session, you will forfeit your session and be
                    liable for a fee equal to the session fee.</li>
                <li>In the unlikely event that a workshop/session has to be
                    cancelled by The Voice Business you will be entitled to
                    receive a full refund of any monies paid. Alternatively, you
                    may choose to attend a future workshop/session of similar
                    cost and duration. The Voice Business will not be held
                    liable for any other costs that might be incurred as a
                    result of the cancellation.</li>
            </ul>
        </div>
        <div className="form-check my-2">
<input className="form-check-input" type="checkbox" id="tos" ref={tos} />
<label className="form-check-label font-14" htmlFor="tos"><span className="required"> * </span>
In submitting this form, I acknowledge that I have read and accepted the <a href="/page/terms" target="_blank" className="red-text text-decoration-none">Terms and Conditions</a> and <a href="/page/privacy" target="_blank" className="red-text text-decoration-none">Privacy Policy.</a>
            </label>
        </div>
</div>
                                    <div className="col-lg-12">
                                        <button className="theme-btn mt-3 mx-auto" onClick={() => sendBooking()}>Send
                                            <i>
                                                <svg width="23" height="12" viewBox="0 0 23 12" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1 5.2825C0.603738 5.2825 0.282504 5.60374 0.282504 6C0.282504 6.39626 0.603738 6.7175 1 6.7175V5.2825ZM21.8364 6.50735C22.1166 6.22715 22.1166 5.77285 21.8364 5.49265L17.2703 0.92654C16.9901 0.646341 16.5358 0.646341 16.2556 0.92654C15.9754 1.20674 15.9754 1.66103 16.2556 1.94123L20.3143 6L16.2556 10.0588C15.9754 10.339 15.9754 10.7933 16.2556 11.0735C16.5358 11.3537 16.9901 11.3537 17.2703 11.0735L21.8364 6.50735ZM1 6.7175H21.329V5.2825H1V6.7175Z"
                                                        fill="white" />
                                                </svg>
                                            </i>
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<F />
</>

}

export default Booking;
