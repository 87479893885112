import { useEffect, useState } from "react";
import Courses from "../components/courses";
import F from "../components/footer";
import H from "../components/header";

const CoursesPage = () => 
{ const [data, setData] = useState({});
window.document.title = "Courses for Executive speaking skills, Public speaking, Personal Voice coaching and more";
window.document.querySelector('meta[name="description"]').setAttribute("content", "List of courses available at The Voice Business. Courses including public speaking, elocution lessons, personal voice coaching, and more");
window.document.querySelector('meta[name="keywords"]').setAttribute("content", "Executive Speaking Skills, Elocution and Vocabulary, Personal Voice Coaching, Accent Reduction, Public Speaking, Presentation Skills Coaching, Voice Skills for Virtual Presenters, Interview Skills, Voice Over Broadcasting Skills");
useEffect(() => {fetch('https://api.thevoicebusiness.com.au/page/home')
.then(res => res.json()).then(jr => { let c = JSON.parse(jr['content']); setData(c) })}, []);
return <>
<H />
<Courses data={data} />
<F />
</>}

export default CoursesPage;