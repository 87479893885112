import { useRef } from "react";
const Contactform = (props) => {
    fetch('https://api.thevoicebusiness.com.au/ping', {credentials: 'include'});
    const reason = useRef(); const reason2 = useRef();
    const name = useRef(); const company = useRef(); const email = useRef(); const phone = useRef();
    const message = useRef(); const ms = useRef();
    const sectionc = useRef();
    const detectbooking = () => {
    if (reason.current.value === 'Make a booking') {
        window.location.href = '/booking';
    }
    }
    const sendContact = () => {
    document.querySelectorAll('.is-invalid').forEach(element => { element.classList.remove('is-invalid'); });
    const contactData = {};
    contactData['reason'] = reason.current.value;
    contactData['reason2'] = reason2.current.value;
    contactData['name'] = name.current.value;
    contactData['company'] = company.current.value;
    contactData['email'] = email.current.value;
    contactData['phone'] = phone.current.value;
    if (props.city) {
        contactData['message'] = message.current.value + `<br /><hr /><br /><p><b>Ref:</b> Voice Training in ${props.city}</p>`;
    } else {
        contactData['message'] = message.current.value;
    }
    fetch('https://api.thevoicebusiness.com.au/contact', { headers: {'Content-Type': 'application/json'},
    credentials: 'include', method: 'POST', body: JSON.stringify(contactData)
    }).then((res) => res.status === 409 ? res.text().then((rb => {
        let errFields = rb.split(','); errFields.pop();
        errFields.forEach(element => {
            eval(element).current.classList.add('is-invalid');
        });
        window.scroll(0, sectionc.current.offsetTop);
    })) : sentForm());
    }
    
    function sentForm() {
    ms.current.innerHTML = '';
    sectionc.current.innerHTML = '<div class="col-3"><svg class="w-100" version="1.1" x="0px" y="0px" viewBox="0 0 98.5 98.5" enableBackground="new 0 0 98.5 98.5"><path class="checkmark" fill="none" strokeWidth="8" strokeMiterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/></svg></div><div class="col-9"><h3>Thank you for your message.<br />We have received it and will respond shortly.</h3></div>';
    window.scroll(0, sectionc.current.offsetTop - 200);
    }
return <section className="bg-with-lines booking-wrapper">
        <div className="container">
            <div className="row">
                <div className="col-xl-9 col-lg-11 col-12 mx-auto">
                    <div className="outer-box">
                        <div className="d-flex align-items-center justify-content-center mb-3" data-aos="flip-right" data-aos-duration="1000">
<h3 className="mx-auto bg-title third-title text-white">ONLINE ENQUIRIES</h3>
                        </div>
                        <div className="white-box pt-5">
                            <p className="para text-center mt-sm-0 mt-3" ref={ms}>Send us a message by filling in the form below. We'll get right back to you.</p>
                            <div className="row" ref={sectionc}>
                                <div className="form-wrapper row">
                                    <div className="col-lg-12">
                                        <div className="col-md-12 mb-3">
<label htmlFor="reason" className="form-label">Let us know how we can help you. Please select.<span className="required">*</span></label>
<select className="form-select form-control" aria-label="reason to contact" ref={reason} onChange={() => detectbooking()}>
    <option value="Make an enquiry">make an enquiry</option>
    <option value="Make a booking">make a booking</option>
    <option value="Change a booking">change a booking</option>
    <option value="Contact customer service">contact customer service</option>
    <option value="Get banking details">get banking details</option>
    <option value="Receive some information">receive some information</option>
    <option value="Send some love">send some love</option>
    <option value="Receive a course recommendation">receive a course recommendation</option>
    <option value="Other">Other</option>
</select></div></div>
<div className="col-lg-12">
    <div className="col-md-12 mb-3">
<label htmlFor="reason2" className="form-label">What Course or Product would you like to know more about? select.<span className="required">*</span></label>
<select className="form-select form-control" aria-label="select course or product" ref={reason2}>
<option value="">-- Please select --</option>
<option value="Voice Coaching">Voice Coaching</option>
<option value="Executive Speaking">Executive Speaking</option>
<option value="Presentation Skills">Presentation Skills</option>
<option value="Public Speaking">Public Speaking</option>
<option value="Interview Coaching">Interview Coaching</option>
<option value="Media Training">Media Training</option>
<option value="Telephone Skills">Telephone Skills</option>
<option value="Telemarketing">Telemarketing</option>
<option value="Voice">Voice-Over</option>
<option value="Team Communication">Team Communication</option>
<option value="Facilitation">Facilitation</option>
<option value="Emotional Intelligence">Emotional Intelligence</option>
<option value="Speech Writing">Speech Writing</option>
<option value="Consulting Advice">Consulting Advice</option>
<option value="Books">Books/Videos</option>
<option value="Newsletter">Newsletter</option>
<option value="Talks and Keynotes">Talks & Keynotes</option>
<option value="Schedules">Schedules</option>
<option value="Press Releases">Press Releases</option>
<option value="Company">Company</option>
<option value="Trainers">Trainers</option>
<option value="Other">Other...</option>
        </select>
<div className="invalid-feedback">Please select an option.</div>
                                            <span className="sub-text d-inline-block mt-2">However, if you want to
                                                directly apply for a course; please <a className="text-decoration-none" href="/booking"
                                                    target="_blank" rel="noopener noreferrer">click here to book the course.</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrapper row">
                                    <h4 className="third-title text-blue text-start">Tell us how to get in touch with
                                        you:</h4>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="fName" className="form-label">Name <span className="required">*</span> :</label>
                                        <input type="text" className="form-control" id="name" ref={name} />
                                        <div className="invalid-feedback">Please enter your name</div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="email" className="form-label">Email <span className="required">*</span> :</label>
                                        <input type="email" className="form-control" id="email" ref={email} />
                                        <div className="invalid-feedback">Please enter the correct email address</div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="company" className="form-label">Company :</label>
                                        <input type="text" className="form-control" id="company" ref={company} />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="telhone" className="form-label">Telephone <span className="required">*</span> :</label>
                                        <div className="input-group has-validation">
                                            <input type="text" className="form-control" id="telhone"
                                                aria-describedby="inputGroupPrepend" ref={phone} />
                                        <div className="invalid-feedback">Please enter a valid phone number</div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="message" className="form-label textarea-label">Message <span className="required">*</span> :</label>
                                        <textarea className="form-control" id="message" rows="3" ref={message}></textarea>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <div className="form-check my-2">
                                            <input className="form-check-input" type="checkbox"
                                                id="flexCheckfriendcolleague" />
                                            <label className="form-check-label font-14 mt-1"
                                                htmlFor="flexCheckfriendcolleague"><span className="required"> * </span>In submitting this form, I acknowledge that I have read and accepted the <a href="/page/terms" target="_blank" className="red-text">Terms and Conditions</a> and <a href="/page/privacy" target="_blank" className="red-text">Privacy Policy</a>.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div
                                            className="d-flex justify-content-center align-items-center column-gap-3 flex-wrap">
                                            <button className="theme-btn mt-3 mb-md-0 mb-3" onClick={() => sendContact()}>Send
                                                <i>
                                                    <svg width="23" height="12" viewBox="0 0 23 12" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M1 5.2825C0.603738 5.2825 0.282504 5.60374 0.282504 6C0.282504 6.39626 0.603738 6.7175 1 6.7175V5.2825ZM21.8364 6.50735C22.1166 6.22715 22.1166 5.77285 21.8364 5.49265L17.2703 0.92654C16.9901 0.646341 16.5358 0.646341 16.2556 0.92654C15.9754 1.20674 15.9754 1.66103 16.2556 1.94123L20.3143 6L16.2556 10.0588C15.9754 10.339 15.9754 10.7933 16.2556 11.0735C16.5358 11.3537 16.9901 11.3537 17.2703 11.0735L21.8364 6.50735ZM1 6.7175H21.329V5.2825H1V6.7175Z"
                                                            fill="white" />
                                                    </svg>
                                                </i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Contactform;