import { useEffect, useState } from "react";
import F from "../components/footer";
import H from "../components/header";

const Press = () => 
{
const [blogs, setBlogs] = useState([]);
useEffect(() => { fetch('https://api.thevoicebusiness.com.au/press').then(r => r.json())
.then(jr => setBlogs(jr));
window.document.title = "Press / Blog - The Voice Business";
window.document.querySelector('meta[name="description"]').setAttribute("content","Get to know the latest and the greatest happening at The Voice Business.");
window.document.querySelector('meta[name="keywords"]').setAttribute("content","press blog, impressum, blog, the voice business");
}, []);
return <><H />

<section className="d-flex flex-column position-relative">
<video autoPlay muted playsInline loop id="brandvideo" className="mt-0">
        <source src="/video/press-blog.mp4" type="video/mp4" />
</video>
    <div className="container">
        <div className="row">
            <div className="col-md-4 mx-auto position-relative">
            <div className="position-absolute bottom-0 mb-3">
                <h1 className="main-title p-3">Press / <span className="red-text weight-font">Blog</span>
                </h1>
            </div>
        </div>
        </div>
    </div>
</section>

<div className="container mb-5">
{blogs.map((el, index) => <div key={index} className="row blog p-3">
    <div className="col-12 blue-text">
        <h3>{el.heading}</h3>
    </div>
<div className="col-12 col-md-4">
<img src={`https://assets.thevoicebusiness.com.au/${el.dp}`} alt={el.heading} className="w-100" />
</div>
<div className="col-12 col-md-8">
    <h4>{el.title}</h4>
    <div>{el.content}</div>
</div>
</div>)}
</div>
<F /></>
}
export default Press;