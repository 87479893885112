import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/home";
import About from "./screens/about";
import Contact from "./screens/contact";
import Booking from "./screens/booking";
import Courses from './screens/courses';
import CourseDetails from "./screens/courseDetails"
import Page from "./screens/page";
import Press from "./screens/press";
import Internationalpage from './screens/internationalpage';
import City from './screens/city';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/booking' element={<Booking />} />
      <Route path='/national-program' element={<Courses />} />
      <Route path='/international/:country' element={<Internationalpage />} />
      <Route path='/domestic/:city' element={<City />} />
      <Route path='/course/:url' element={<CourseDetails />} />
      <Route path='/page/:url' element={<Page />} />
      <Route path='/press' element={<Press />} />
    </Routes>
    </BrowserRouter>
);
