import { useState, useEffect } from "react";
import Coursebox from "./coursebox.js";

const Courses = (props) => {
const [loading, setLoading] = useState(false);
const [data, setData] = useState({'Course blurb': '<div class="loadcourse w-100"></div>'});
useEffect(() => {
    if (props.data['Course blurb']) {
        setData(props.data);
    } else {
        fetch('https://api.thevoicebusiness.com.au/page/home')
        .then(res => res.json()).then(jr => { let c = JSON.parse(jr['content']); setData(c) });
    }
    fetch('https://api.thevoicebusiness.com.au/courses?list=yes').then(res => res.json())
.then(jr => setLoading(jr)
);}, []);

return <section className="courses-wrapper">
<div className="container">
    <div className="row">
        <div className="col-12">
            <p className="mb-0 sub-title red-text text-center">The Voice Business</p>
            <h2 className="second-title text-center">Choose the course that's right for you</h2>
            <div className="col-md-9 mx-auto pt-3">
                <div className="para text-center mt-md-5 mt-2 p-2 shadow shadow-md rounded rounded-5" dangerouslySetInnerHTML={{__html: data['Course blurb'].replace('font-size: ', '')}}></div>
            </div>
        </div>
    </div>
<div className="row courses-inner-wrapper">
{ (loading) ? loading.map(el => <Coursebox {...el} key={el.id} />) :
<>
<div className="col-lg-4 col-md-6 col-12 loadcourse"></div><div className="col-lg-4 col-md-6 col-12 loadcourse"></div><div className="loadcourse col-lg-4 col-md-6 col-12"></div>
<div className="col-lg-4 col-md-6 col-12 loadcourse"></div><div className="col-lg-4 col-md-6 col-12 loadcourse"></div><div className="loadcourse col-lg-4 col-md-6 col-12"></div>
<div className="col-lg-4 col-md-6 col-12 loadcourse"></div><div className="col-lg-4 col-md-6 col-12 loadcourse"></div><div className="loadcourse col-lg-4 col-md-6 col-12"></div>
</> }
    </div>
</div>
</section>
}

export default Courses;