import { useEffect, useState } from 'react';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const T = () => {
const l = () => { const swiper = new Swiper(".testiSlider", {
modules: [Navigation], slidesPerView: 1, spaceBetween: 20,
initialSlide: 1, centeredSlides: true, loop: true, autoplay: true,
navigation: { nextEl: "#testiSwiperNavigation .swiper-button-next",
prevEl: "#testiSwiperNavigation .swiper-button-prev", },
breakpoints: { 992: { slidesPerView: 3, spaceBetween: 20, }, },
}); }
const [u_, _u] = useState([]);
useEffect(() => { fetch('https://api.thevoicebusiness.com.au/testimonials')
.then( _=>_.json()).then((r)=>{_u(r); setTimeout(l, 1000);})},[]);

return <section className="testi-wrapper" id="testimonial">
<div className="container">
    <div className="row">
        <div className="col-12">
            <p className="mb-0 sub-title red-text text-start">The Voice Business</p>
            <h2 className="second-title text-start">What people are saying</h2>
        </div>
    </div>
    <div className="row testi-inner-wrapper" data-aos="flip-right" data-aos-duration="1000">
        <div className="testiSlider swiper-container swiper">
            <div className="swiper-wrapper">
                {
                u_.map((d) => <div className="swiper-slide" key={d.id}>
                        <div className="testi-box">
                            <div className="img-wrapper">
                                <img src={`https://assets.thevoicebusiness.com.au/${d.picture}`} alt={`picture of ${d.name}`} />
                            </div>
                            <div className="text-wrapper">
                                {d.testimonial}
                                <div className="author-wrapper">
                                    <h4>{d.name}</h4>
                                    <div className="ratings d-flex">
                                        <i><img src="/images/icons/start-icon.svg" alt="star for ratings" /></i>
                                        <i><img src="/images/icons/start-icon.svg" alt="star for ratings" /></i>
                                        <i><img src="/images/icons/start-icon.svg" alt="star for ratings" /></i>
                                        <i><img src="/images/icons/start-icon.svg" alt="star for ratings" /></i>
                                        <i><img src="/images/icons/start-icon.svg" alt="star for ratings" /></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <div className="swiper-navigation" id="testiSwiperNavigation">
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </div>
        </div>
    </div>
</div>
</section>
}

export default T;