import { useEffect, useRef, useState } from "react";
import F from "../components/footer";
import H from "../components/header";
import Testimonial from "../components/testimonial";
import { useParams, Link } from "react-router-dom";

const CourseDetails = () => {
const {url} = useParams();
const [title, setTitle] = useState('course');
const [description, setDescription] = useState('loading...');
const [testimonial, setTestimonial] = useState([]);
const [background, setBackground] = useState('https://www.thevoicebusiness.com.au/images/lines-img.png');
const scheduleref = useRef();
const learningoutcomes = useRef();
const [id, setId] = useState(0);
useEffect(() => {
    if (url == null) { return; }
    fetch('https://api.thevoicebusiness.com.au/course/'+url).then(res => {if (res.ok) return res.json()})
    .then(jr => {
        window.scroll(0,0);
        window.document.title = jr.title + ' training and coaching at Sydney, Brisbane, Melbourne, Perth, Adelaide and Canberra';
        window.document.querySelector('meta[name="description"]').setAttribute("content", jr.description);
        window.document.querySelector('meta[name="keywords"]').setAttribute("content", jr.keywords);
        setId(jr.id); setTitle(jr.title); setDescription(jr.description);
        setTestimonial(jr.coursetestimonials);
        if (jr.background) { setBackground(`https://assets.thevoicebusiness.com.au/${jr.background}`); }
        learningoutcomes.current.innerHTML = jr.learningoutcomes;
        scheduleref.current.innerHTML = jr.schedule;
    })
    .catch((error) => console.log(error))
}, [url])
return <>
<H />
<main className="about-page pages">
    <section style={{padding: '60px 0px', backgroundImage: `url(${background})`,
    backgroundPosition: 'center', backgroundSize: 'cover', height: '550px'}}>
        <div className="container">
            <div className="row">
                <div className="col-12 mx-auto aos-init aos-animate" data-aos="fade-right" data-aos-duration="2000">
                    <div className="main-title text-center pb-3 mt-250">
                    <h1 className="py-3" title={title}>{title}</h1>
                        <p className="para">{description}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

<section className="bg-with-lines booking-wrapper">
    <div className="container">
        <div className="row course-new">
            <div className="col-xl-10 col-12 mx-auto">
                <div className="outer-box">
                    <div className="course-box">
                                <div className="white-box white-box2 mt-0">
                                    <div className="row ">
                                        <div className="form-wrapper row">
<div className="col-12 coursedetails" ref={learningoutcomes}>
</div>
            </div>
        </div>
    </div>
</div>

<div className="white-box my-4 row">
    <div className="col-md-6">
<h5 className="text-blue">Class Time:</h5>
                <p className="mb-0 pt-2 light-text2">Classes are available weekdays between 6.00am-6.00pm<br />
Workshops are from 9.00am-4.30pm</p>
<div className="info3 pt-4 d-block">
    <h5 className="text-blue">Key features:</h5>
<p className="info-text">MP3s and Work notes included </p>
<p className="info-text">Post Course Evaluation </p>
<p className="info-text">A Certificate of Completion will be awarded to each successful candidate.</p>
</div>
</div><div className="col-md-6">
<h5 className="text-blue">Questions?</h5>
    <p className="info-text"><a href="tel:1300922122">1300 922 122</a></p>
<h5 className="text-blue">Contact Head Office:</h5>
<p className="info-text pt-2"><b>The Voice Business</b>,<br />Berry St,<br />North Sydney,<br />NSW 2060,<br />Australia</p>
</div></div>
<div className="mt-2 mb-4 col-md-3 mx-auto">
<Link to={`/booking?courseid=${id}`} className="theme-btn mt-3 mb-md-0">Book this course<i><svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 5.2825C0.603738 5.2825 0.282504 5.60374 0.282504 6C0.282504 6.39626 0.603738 6.7175 1 6.7175V5.2825ZM21.8364 6.50735C22.1166 6.22715 22.1166 5.77285 21.8364 5.49265L17.2703 0.92654C16.9901 0.646341 16.5358 0.646341 16.2556 0.92654C15.9754 1.20674 15.9754 1.66103 16.2556 1.94123L20.3143 6L16.2556 10.0588C15.9754 10.339 15.9754 10.7933 16.2556 11.0735C16.5358 11.3537 16.9901 11.3537 17.2703 11.0735L21.8364 6.50735ZM1 6.7175H21.329V5.2825H1V6.7175Z" fill="white"></path></svg></i></Link>
</div>
<div ref={scheduleref} className="table-wrapper"></div>
            </div>
            <div className="white-box mt-3">
            <h3 className="my-4">Here's what people say</h3>
<div className="row">
{(testimonial.map((el,x) => <Testimonial key={x} {...el} />))}
</div>
<div className="mt-5 col-md-3 mx-auto">
<Link to={`/booking?courseid=${id}`} className="theme-btn mt-3 mb-md-0 mb-3">Book this course<i><svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 5.2825C0.603738 5.2825 0.282504 5.60374 0.282504 6C0.282504 6.39626 0.603738 6.7175 1 6.7175V5.2825ZM21.8364 6.50735C22.1166 6.22715 22.1166 5.77285 21.8364 5.49265L17.2703 0.92654C16.9901 0.646341 16.5358 0.646341 16.2556 0.92654C15.9754 1.20674 15.9754 1.66103 16.2556 1.94123L20.3143 6L16.2556 10.0588C15.9754 10.339 15.9754 10.7933 16.2556 11.0735C16.5358 11.3537 16.9901 11.3537 17.2703 11.0735L21.8364 6.50735ZM1 6.7175H21.329V5.2825H1V6.7175Z" fill="white"></path></svg></i></Link>
</div>
</div>
</div>
    </div>
    </div>
</section>

<section className="contact-info bg-blue">
    <div className="container">
        <div className="contact-box">
            <div className="row">
                <div className="col-lg-6 my-2 aos-init" data-aos="fade-right" data-aos-duration="1000">
                    <div className="icon-box">
                        <i className="fa fa-volume-control-phone text-blue icon"></i>
                        <div className="text-wrapper">
                            <h5>Domestic</h5>
                            <a href="tel:1300922122">1300 922 122</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 my-2 aos-init" data-aos="fade-left" data-aos-duration="1000">
                    <div className="icon-box">
                        <i className="fa fa-volume-control-phone text-blue icon"></i>
                        <div className="text-wrapper">
                            <h5>International</h5>
                            <a href="tel:+61299574208">+61 2 9957 4208</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</main>
<F />
</>
}

export default CourseDetails;
